import React from 'react';
import { ParagraphColumn } from '../../../generated/types';
import { SimpleGrid } from '@chakra-ui/react';
import PARAGRAPH_MAPPING from '../../../config/paragraph-mapping';

export interface ColumnProps {
  data: ParagraphColumn;
}

const Column: React.FC<ColumnProps> = ({ data }) => {
  return (
    <SimpleGrid
      spacing={4}
      className="ParagraphColumn"
      __css={{
        '.ParagraphText': {
          marginY: 0,
        },
        '.ParagraphContainer': {
          paddingX: 0,
        },
        '.ParagraphImage': {
          my: 0,
        },
      }}
    >
      {data.content?.map((data) => {
        if (PARAGRAPH_MAPPING[data.__typename]) {
          const Component = PARAGRAPH_MAPPING[data.__typename];

          if (!Component) return null;

          return <Component key={data.id} data={data} />;
        }

        return null;
      })}
    </SimpleGrid>
  );
};

export default Column;
